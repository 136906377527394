import { LocationQueryValue, RouteLocationNormalizedLoaded } from 'vue-router';

/**
 * Allows reading query parameters from routes as strings
 * without relying on type assertions
 */
export const getStringQueryFromRoute = (
  route: Pick<RouteLocationNormalizedLoaded, 'query'>,
  queryParam: string,
): LocationQueryValue => {
  const value = route.query[queryParam];
  return typeof value === 'string' ? value : null;
};
